var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Заполните данные")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Логин","prepend-icon":"$vuetify.icons.account","rules":_vm.loginRules},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"E-mail","prepend-icon":"$vuetify.icons.gmail","required":"","rules":_vm.emailRules},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-menu',{ref:"birthday_menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Дата рождения","prepend-icon":"$vuetify.icons.calendar","readonly":""},model:{value:(_vm.birthday_date),callback:function ($$v) {_vm.birthday_date=$$v},expression:"birthday_date"}},on))]}}]),model:{value:(_vm.birthday_menu),callback:function ($$v) {_vm.birthday_menu=$$v},expression:"birthday_menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"locale":"ru-ru","first-day-of-week":1,"max":new Date().toISOString().substr(0, 10),"min":"1950-01-01","scrollable":""},model:{value:(_vm.birthday_date),callback:function ($$v) {_vm.birthday_date=$$v},expression:"birthday_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.birthday_menu = false}}},[_vm._v("Закрыть")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.birthday_menu.save(_vm.birthday_date)}}},[_vm._v("Принять")])],1)],1),_c('v-checkbox',{staticClass:"register-accept",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Я согласен с "),_c('router-link',{attrs:{"to":{
                          name: 'Page',
                          params: { slug: 'polzovatelskoe-soglashenie' },
                        }}},[_vm._v("Пользовательским соглашением")]),_vm._v(", "),_c('router-link',{attrs:{"to":{
                          name: 'Page',
                          params: { slug: 'privacy-policy' },
                        }}},[_vm._v("Политикой конфиденциальности")]),_vm._v("и "),_c('router-link',{attrs:{"to":{
                          name: 'Page',
                          params: { slug: 'pravila-sajta' },
                        }}},[_vm._v("Правилами сайта")])],1)]},proxy:true}]),model:{value:(_vm.accept),callback:function ($$v) {_vm.accept=$$v},expression:"accept"}}),_c('v-btn',{staticClass:"mt-2 register-button",attrs:{"disabled":!_vm.accept,"dark":"","color":"primary"},on:{"click":_vm.submit}},[_vm._v("Регистрация")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }