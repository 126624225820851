<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card :loading="loading" class>
          <v-form v-model="valid" ref="form">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Заполните данные</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Логин"
                    prepend-icon="$vuetify.icons.account"
                    :rules="loginRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="email"
                    label="E-mail"
                    prepend-icon="$vuetify.icons.gmail"
                    required
                    :rules="emailRules"
                  ></v-text-field>
                  <v-menu
                    ref="birthday_menu"
                    v-model="birthday_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <!-- :return-value.sync="dates" -->
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="birthday_date"
                        label="Дата рождения"
                        prepend-icon="$vuetify.icons.calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="ru-ru"
                      ref="picker"
                      :first-day-of-week="1"
                      v-model="birthday_date"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="birthday_menu = false"
                        >Закрыть</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.birthday_menu.save(birthday_date)"
                        >Принять</v-btn
                      >
                    </v-date-picker>
                  </v-menu>

                  <v-checkbox class="register-accept" v-model="accept">
                    <template v-slot:label>
                      <div>
                        Я согласен с
                        <router-link
                          :to="{
                            name: 'Page',
                            params: { slug: 'polzovatelskoe-soglashenie' },
                          }"
                          >Пользовательским соглашением</router-link
                        >,
                        <router-link
                          :to="{
                            name: 'Page',
                            params: { slug: 'privacy-policy' },
                          }"
                          >Политикой конфиденциальности</router-link
                        >и
                        <router-link
                          :to="{
                            name: 'Page',
                            params: { slug: 'pravila-sajta' },
                          }"
                          >Правилами сайта</router-link
                        >
                      </div>
                    </template>
                  </v-checkbox>
                  <v-btn
                    :disabled="!accept"
                    class="mt-2 register-button"
                    dark
                    color="primary"
                    @click="submit"
                    >Регистрация</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import { bus } from "../mixins/bus.js";
export default {
  data: () => ({
    valid: true,
    loading: false,
    name: "",
    email: "",
    socId: null,
    token: null,
    remember: false,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    loginRules: [
      (v) => !!v || "Поле обязательно",
      (v) => (v && v.length >= 3) || "Минимум 3 символа",
      //   v => /^[\w-]+$/gim.test(v) || "Только латинские символы и цифры",
      (v) => /^[\w-]*$/.test(v) || "Введите ваше имя английскими буквами.",
    ],
    emailRules: [
      (v) => !!v || "Поле обязательно",
      (v) => /.+@.+\..+/.test(v) || "Не верно введен E-mail",
    ],
    accept: true,
    birthday_menu: false,
    birthday_date: null,
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    submit() {
      event.preventDefault();
      if (!this.validate()) return;
      this.loading = true;
      this.$store
        .dispatch("socialRegister", {
          name: this.name,
          email: this.email,
          birthday_date: this.birthday_date,
          id: this.socId,
          token: this.token,
        })
        .then(() => {
          this.loading = false;
          // this.$router.push({ name: "Home" });
          if (this.$route.query.return_url) {
            this.$router.push(this.$route.query.return_url);
          } else {
            this.$router.push({ name: "Home" });
          }
        });
    },
    clear() {
      this.email = "";
    },
    getData() {
      let app = this;
      this.$store
        .dispatch("getSocData", {
          id: app.socId,
          token: app.token,
        })
        .then((response) => {
          let socialAccount = response.data.socialAccount;
          app.name = socialAccount.nickname;
          app.email = socialAccount.email;
        });
    },
  },
  watch: {
    birthday_menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  mounted() {
    this.socId = this.$route.params.id;
    this.token = this.$route.params.token;
    this.getData();
  },
};
</script>
